<template>
  <div class="selectGoods">
    <nav-bar :nav-info='navInfo'></nav-bar>
    <el-form class="demo-ruleForm" :model="ruleForm" ref="ruleForm"
             label-position="left"
             label-width="130px">
      <el-form-item class="stripe" label="当前时间">
        <div class="isDisabled">{{ ruleForm.operateTime | formatDate }}</div>
      </el-form-item>
      <div class="threadiness"></div>
      <el-form-item label="当前位置">
        <div @click="popClick" class="isDisabled">{{ ruleForm.operateAddress }}</div>
      </el-form-item>

      <el-form-item class="stripe" label="操作人电话" prop="operatePhone" required>
        <el-input v-model="ruleForm.operatePhone" placeholder="请输入操作人电话"></el-input>
      </el-form-item>
      <div class="threadiness"></div>
      <el-form-item label="操作人姓名" prop="operatePeople" required>
        <el-input v-model="ruleForm.operatePeople" placeholder="请输入操作人姓名"></el-input>
      </el-form-item>
      <div class="threadiness"></div>
      <el-form-item label="车牌号" prop="carNo" required>
        <el-input v-model="ruleForm.carNo" placeholder="请输入车牌号"></el-input>
      </el-form-item>
      <div class="threadiness"></div>
      <el-form-item class="stripe" label="状态" prop="isAbnormal">
        <el-radio-group v-model="ruleForm.isAbnormal" size="mini" class="radio1" @change="isOneChange">
          <el-radio-button label="正常"></el-radio-button>
          <el-radio-button label="异常"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <div class="threadiness"></div>
      <el-form-item label="备注" prop="remark"
                    :rules="[{required:isOne,message: '必填',trigger: 'blur'}]"
      >
        <el-input class="input1" type="text" v-model="ruleForm.remark" placeholder="请填写相关备注信息"></el-input>
      </el-form-item>
      <!-- 上传装车完成图片 -->
      <div v-if="ruleForm.type==1">
        <div class="threadiness"></div>
        <el-form-item label="上传装车完成图片" style="white-space: nowrap;" required></el-form-item>
        <div class="upLoadPic">
          <van-uploader v-model="ruleForm.loadingPicture" :after-read="afterRead1" :before-delete="beforeDelete1"
                        multiple/>
        </div>
      </div>
      <!-- 上传最短导航图片 -->
      <div v-if="ruleForm.type==1">
        <div class="threadiness"></div>
        <el-form-item label="上传最短导航图片" style="white-space: nowrap;" required></el-form-item>
        <div class="upLoadPic">
          <van-uploader v-model="ruleForm.navigationPicture" :after-read="afterRead2" :before-delete="beforeDelete2"
                        multiple/>
        </div>
      </div>
      <!-- 上传图片 -->
      <div>
       <div class="threadiness"></div>
        <el-form-item label="上传图片" :required="ruleForm.type==1"></el-form-item>
        <div class="upLoadPic">
          <van-uploader v-model="ruleForm.uploadPicture" :after-read="afterRead3" :before-delete="beforeDelete3" multiple/>
        </div>
      </div>
      <!-- 上传回执单 -->
      <div v-if="ruleForm.type!=2 ">
        <div class="threadiness"></div>
        <el-form-item label="上传回执单" required></el-form-item>
        <div class="upLoadPic">
          <van-uploader v-model="ruleForm.singleReceipt" :after-read="afterRead4" :before-delete="beforeDelete4" multiple/>
        </div>
      </div>
      <el-button type="warning" class="tjBtn" @click="onSubmit('ruleForm')">提交</el-button>
    </el-form>
    <van-popup
      v-model="popShow"
      round
      position="bottom"
      :style="{ height: '65%' }"
    >
      <div class="JDmap">
        <div id="container"></div>
                <div class="address">
          <el-input @input="geoMap" v-model="address" placeholder="当前选择地址"></el-input>
          <el-button @click="mapAddress" type="primary">确定</el-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Dialog, Toast, Uploader} from 'vant';
import NavBar from '../../components/NavBar/NavBar.vue'
import { debounce } from 'lodash';
var { MapTypes } = JDMap.MapEnums
var geoCode
export default {
  components: {
    NavBar
  },
  data() {
    return {
      navInfo: {
        title:'运力监控',
        left:true,
        rigth:true,
        path:'/main'
      },
      // api: '/tezhong/form',//正式路径拼接
      // api: '',//测试
      title: this.$route.meta.title, //标题
      posFlag: 1,//1是成功定位，2是定位失败，需手动录入位置信息。
      loadingPicture: [],//装车完成照片地址
      navigationPicture: [],//最短导航图片地址
      uploadPicture: [],//照片地址
      singleReceipt: [],//回执单地址
      isOne: false,
      popShow: false,//选址弹框
      address: '',//当前选址
      ruleForm: {
        waybillId: "",//运单ID
        orderId: "",//订单ID
        type: 2,//打点类型 1起运 2在途 3签收
        isAbnormal: "正常",//0正常 1异常
        lng: '',//经度
        lat: '',//纬度
        operatePeople: "",//姓名
        operatePhone: "",//电话
        operateAddress: "点击选择地址",//地址
        operateTime: "",//时间
        remark: "",//备注
        loadingPicture: [],//装车完成照片地址
        navigationPicture: [],//最短导航图片地址
        uploadPicture: [],//照片
        singleReceipt: [],//回执单
        carNo: "",//车牌号
        receivedNum: "",
        sendWeight: "",//起运重量
        signWeight: "",//签收重量
      },
      rules: {
        operatePhone: [{required: true, message: '必填', trigger: 'blur'}],
        operatePeople: [{required: true, message: '必填', trigger: 'blur'}],
        isAbnormal: [{required: true, message: '必填', trigger: 'change'}],
        carNo: [{required: true, message: '必填', trigger: 'change'}],
      },
      showChangeCar: false,//是否换车
      selectValue: '1',//0为换车是1为不换否
      flag: false,//回执单是否通过验证
      showArea: false,
      // areaList: areaList,
      areaValue: "",
      areaDetailValue: "",
      mark: 0,
      waybillList: []
    };
  },
  created() {
    this.waybillList = JSON.parse(sessionStorage.getItem('waybillList')) || []
    //去重防止用户重复上传引起重复渲染
    let uniqueArr = this.waybillList.reduce((acc, curr) => {
      if (!acc.find(item => item.orderId === curr.orderId)) {
        acc.push(curr);
      }
      return acc;
    }, []);
    this.waybillList = uniqueArr
    this.waybillList.forEach(item => {
      item.uploadPicture = []
      item.singleReceipt = []
      item.uploadPictureUrl = []
      item.singleReceiptUrl = []
    })
  },
  mounted() {
    this.mark = this.$route.query.mark || 0
    this.ruleForm.type = this.$route.query.type || 0
    this.ruleForm.waybillId = this.$route.query.waybillId;
    this.ruleForm.orderId = this.$route.query.orderId;
    this.ruleForm.operateTime = new Date().getTime();
    this.ruleForm.lng = this.$route.query.lng
    this.ruleForm.lat = this.$route.query.lat
    console.log(this.waybillList[0])
    if (this.ruleForm.lng != "定位失败") {
      if (this.ruleForm.type == 2 || this.ruleForm.type == 3) {
        console.log('获取信息')
        this.dataPlayback()
      }
    } else {
      Toast.fail("定位失败")
    }
  },
  filters: {
    formatDate: (value) => {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
  },
  methods: {
    popClick() {
      console.log('123123')
      this.popShow = true
      setTimeout(() => {
        this.Map()
      }, 500);
    },
    Map() {
      var map 
      map = new JDMap.Map("container", {
          zoom: 16,
          fullscreenControl: true,
          detectRetina: true,
          // preferCanvas: true,
      });
      var mark,
          from = MapTypes.QQMAP,
          to = MapTypes.QQMAP;
      var local = new JDMap.service.Location("test");

      local.getLocation().then((res) => {
        console.log(res,'111111111')
        setCenter([res.result.lat, res.result.lng]);
      });

      //设置中心点
      function setCenter(geoLocation) {
        map.setView(new JDMap.LatLng(geoLocation));
        if (mark) {
          map.removeLayer(mark);
        }
        mark = new JDMap.Marker(geoLocation).addTo(map);
      }
      var latlng, geocoder
      //地图点击时，获取点击地经纬度
      map.on('click', (e) => {
        console.log(e)
        latlng = e.latlng
        regeoCode()
      })
      let regeoCode = () => {
        if (!geocoder) {
          geocoder = new JDMap.service.Geocode("test")
        }
        if (!mark) {
          mark = new AMap.Marker()
          mark.addTo(map)
        }
        mark.setLatLng(latlng)//设置标记的位置
        console.log(latlng,'6666')
        var geoLocation = { 
          lat: latlng.lat, 
          lng: latlng.lng,
        }
        geocoder.regeo(geoLocation)
        .then(res => {
          console.log(res,99999)
          this.address = res.result.address
          this.ruleForm.lng = res.result.longitude
          this.ruleForm.lat = res.result.latitude

        })
        // mark.setMap(map);//在地图上显示一个标记
      }
      geoCode = () => {
         if (!geocoder) {
          geocoder = new JDMap.service.Geocode("test")
        }
        if (!mark) {
          mark = new JDMap.Marker()
          mark.addTo(map)
        }
        console.log(this.address,'获取地址')
        let geoLocation = {
          address: this.address
        }
        geocoder.geo(geoLocation)
        .then(res => {
          console.log(res,'正向解析')
          this.ruleForm.lng = res.result.longitude
          this.ruleForm.lat = res.result.latitude
          var geoLocation1 = [res.result.latitude, res.result.longitude]
          setCenter(geoLocation1)
        })
      }
    },    
    mapAddress() {
      console.log(99999,'提交')
      this.ruleForm.operateAddress = this.address
      this.popShow = false
    },
    geoMap: debounce(function() {
      console.log('123123')
      geoCode()
    }, 1500),
    isOneChange() {//改变状态值
      let that = this;
      console.log(that.ruleForm.isAbnormal)
      if (that.ruleForm.isAbnormal == "正常") {
        that.isOne = false
      } else if (that.ruleForm.isAbnormal == "异常") {
        that.isOne = true
      }
    },
    dataPlayback() {//数据回显
      let driverInfo = JSON.parse(sessionStorage.getItem("driverInfo")) || null
      // console.log(driverInfo.operatePeople)
      if(driverInfo) {
        this.ruleForm.operatePeople = driverInfo.operatePeople
        this.ruleForm.operatePhone = driverInfo.operatePhone
        this.ruleForm.carNo = driverInfo.carNo
      }
    },
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    //上传装货图片
    async afterRead1(file, detail, obj,index) {//图片上传
        let param = new FormData(), that = this, place;
        Toast.loading({duration: 0, forbidClick: true, message: "上传中"})
        place = this.ruleForm.waybillId;
        param.append('waybillId', that.ruleForm.waybillId);
        param.append('file', file.file);
        let rst = await this.$Api.uploadPictures(place, param)
        console.log(rst)
        Toast.clear();
        if (rst.data.success) {
          that.loadingPicture.push("/scancode/" + rst.data.data[0].Picture)//添加scancode/正确拼配链接路径，在PC端可以正确查看绘制照片
        } else {
          Toast.fail("上传图片失败");
          that.ruleForm.loadingPicture.pop()
        }
    },
    beforeDelete1(file, detail) {//删除图片
      let that = this
      that.loadingPicture.pop(detail.index)
      return file
    },
    //上传最短导航图片
   async afterRead2(file, detail) {//回执上传
      // this.getUrl(file.file);
      // 此时可以自行将文件上传至服务器
      let param = new FormData(), that = this, place;
      Toast.loading({duration: 0, forbidClick: true, message: "上传中"})
      param.append('waybillId', this.ruleForm.waybillId);
      place = this.ruleForm.waybillId;
      param.append('file', file.file);
      let rst = await this.$Api.uploadPictures(place, param)
      if (rst.data.success) {
        that.navigationPicture.push("/scancode/" + rst.data.data[0].Picture)//添加scancode/正确拼配链接路径，在PC端可以正确查看绘制照片
      } else {
        Toast.fail("上传图片失败");
        that.ruleForm.navigationPicture.pop()
      }
    },
    beforeDelete2(file, detail) {//删除图片
      let that = this
      that.navigationPicture.pop(detail.index)
      return file
    },
    //上传图片
    async afterRead3(file, detail, obj,index) {//图片上传
        let param = new FormData(), that = this, place;
        Toast.loading({duration: 0, forbidClick: true, message: "上传中"})
        place = this.ruleForm.waybillId;
        param.append('waybillId', that.ruleForm.waybillId);
        param.append('file', file.file);
        let rst = await this.$Api.uploadPictures(place, param)
        console.log(rst)
        Toast.clear();
        if (rst.data.success) {
          that.uploadPicture.push("scancode/" + rst.data.data[0].Picture)//添加scancode/正确拼配链接路径，在PC端可以正确查看绘制照片
        } else {
          Toast.fail("上传图片失败");
          that.ruleForm.uploadPicture.pop()
        }
    },
    beforeDelete3(file, detail) {//删除图片
      let that = this
      that.uploadPicture.pop(detail.index)
      return file
    },
    //上传回执单
   async afterRead4(file, detail) {//回执上传
      // this.getUrl(file.file);
      // 此时可以自行将文件上传至服务器
      let param = new FormData(), that = this, place;
      Toast.loading({duration: 0, forbidClick: true, message: "上传中"})
      param.append('waybillId', this.ruleForm.waybillId);
      place = this.ruleForm.waybillId;
      param.append('file', file.file);
      let rst = await this.$Api.uploadPictures(place, param)
      console.log(rst)
      Toast.clear();
      if (rst.data.success) {
        that.singleReceipt.push("scancode/" + rst.data.data[0].Picture)//添加scancode/正确拼配链接路径，在PC端可以正确查看绘制照片
      } else {
        Toast.fail("上传图片失败");
        that.ruleForm.singleReceipt.pop()
      }
    },
    beforeDelete4(file, detail) {//删除图片
      let that = this
      that.singleReceipt.pop(detail.index)
      return file
    },
    //提交表单
    onSubmit: debounce(async function(formName,obj,idx) {//提交表单
      // let tmpValid = await this.$http.get("/queryWaybill",{params:{ wayBillId:this.ruleForm.waybillId}});
      // let data = tmpValid.data.data[0];
      // let phone = data.driver.phone;
      console.log('防抖')
      let driverInfo = {
        operatePeople: this.ruleForm.operatePeople,//姓名
        operatePhone: this.ruleForm.operatePhone,//电话
        carNo: this.ruleForm.carNo//车牌号
      }
      console.log('保存信息')
      sessionStorage.setItem("driverInfo", JSON.stringify(driverInfo));
      if (this.ruleForm.type == 1 && this.uploadPicture.length <= 0) {
        console.log(this.uploadPicture,'物资交接单')
        Toast.fail("请上传物资交接单")
      } else if (this.ruleForm.type == 3) {
        if(obj && obj.singleReceiptUrl.length<1) {
          Toast.fail("请上传物资交接单")
        } else if(!obj&&this.singleReceipt.length <= 0){
          Toast.fail("请上传物资交接单")
        } else {
          this.upSubmit(formName,obj,idx);
        }
      } else {
        this.upSubmit(formName,obj,idx);
      }
    }, 1000),
    upSubmit(formName,obj,idx) {
      let that = this;
      this.$refs[formName].validate(async valid => {
        if (valid) {          
            this.forwardSubmit(obj,idx);
        }
      })
    },
    async forwardSubmit(obj,idx) {
      const that = this;
      let param = {
        waybillId: this.ruleForm.waybillId,
        orderid: this.ruleForm.orderId,
        type: this.ruleForm.type,
        isAbnormal: "0",
        lng: this.ruleForm.lng,
        lat: this.ruleForm.lat,
        operatePeople: this.ruleForm.operatePeople,
        operatePhone: this.ruleForm.operatePhone,
        operateAddress: this.ruleForm.operateAddress,
        operateTime: this.formatDate(this.ruleForm.operateTime),
        remark: this.ruleForm.remark,
        carNo: this.ruleForm.carNo,
        loadingPicture: this.loadingPicture,
        navigationPicture: this.navigationPicture,
        uploadPicture: this.uploadPicture,
        singleReceipt: this.singleReceipt,
      }
        // let param = new FormData();
        // param.append('waybillId', that.ruleForm.waybillId);
        // if (that.ruleForm.orderId) {
        //   param.append('orderid', that.ruleForm.orderId);
        // }
        // param.append('uploadPicture', that.uploadPicture);
        // param.append('singleReceipt', that.singleReceipt); 
        // param.append('type', that.ruleForm.type);
        // console.log(Number(that.ruleForm.type),'222222222')
        // if (that.ruleForm.isAbnormal == "异常") {
        //   param.append('isAbnormal', 1);
        // } else {
        //   param.append('isAbnormal', 0);
        // }
        // param.append('lng', that.ruleForm.lng);
        // param.append('lat', that.ruleForm.lat);
        // param.append('operatePeople', that.ruleForm.operatePeople);
        // param.append('operatePhone', that.ruleForm.operatePhone);
        // param.append('operateAddress', that.ruleForm.operateAddress);
        // param.append('operateTime', that.formatDate(that.ruleForm.operateTime));
        // param.append('remark', that.ruleForm.remark);
        // param.append('carNo', that.ruleForm.carNo); 
        // param.append('loadingPicture', that.loadingPicture); 
        // param.append('navigationPicture', that.navigationPicture); 
        //起运上传物资交接单
        console.log('上传前', param)
        //验证成功保存信息    
          let res = await this.$Api.forward(param)
          if (res.data.data[0] == 0) {
            Toast.success("提交成功")
            this.back()  
          } else Toast.success("提交失败")
    },
    back() {//返回上一层
      let tmp = {waybillId: this.ruleForm.waybillId};
      if (this.ruleForm.orderId) {
        tmp.orderId = this.ruleForm.orderId;
      }
      this.$router.push({
        path: '/main',
        query: tmp
      });
    },
    onConfirm(values) {
      this.areaValue = values.reduce((rst, v) => {
        rst += v.name;
        return rst;
      }, "");
      this.showArea = false;
    },
    changeNum(str) {
      str = String(str);
      let newStr = '',
          that = this;
      for (let i = 0; i < str.length; i++) {
        if (str[i] == 'a') {
          newStr += '8';
        } else if (str[i] == 'b') {
          newStr += '3';
        } else if (str[i] == 'c') {
          newStr += '1';
        } else if (str[i] == 'd') {
          newStr += '4';
        } else if (str[i] == 'e') {
          newStr += '6';
        } else if (str[i] == 'f') {
          newStr += '7';
        } else if (str[i] == 'g') {
          newStr += '5';
        } else if (str[i] == 'h') {
          newStr += '9';
        } else if (str[i] == 'i') {
          newStr += '2';
        } else {
          newStr += str[i];
        }
      }
      return newStr;

    },
  }
};
</script>

<style lang="scss" scoped>
.selectGoods {
  font-size: 14px;
  width: 100%;
  height: 100%;
  background: #f1f3f5;

  /deep/ .el-form-item {
    background-color: #fff;
    margin: 0
  }

  /deep/ .el-form-item__content {
    width: 64%;
    font-size: 16px;

    .isDisabled {
      color: #606266;
      padding-left: 5%;
    }
  }

  /deep/ .el-input__inner {
    border: 1px solid transparent;
  }

  .stripe { //间隔条
    margin-top: 3%;
  }

  .threadiness { //间隔线
    border: 0.5px solid #E5E5E5;
    width: 90%;
    margin-left: 5%;
  }

  /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner { //单选按钮
    background-color: #FD5C0C;
    border-color: #FD5C0C;
    -webkit-box-shadow: -1px 0 0 0 #FD5C0C;
    box-shadow: -1px 0 0 0 #FD5C0C;
  }

  /deep/ .van-uploader__upload { //图片上传背景图
    border: 2px dashed #ebedf0;
  }

  /deep/ .van-uploader__preview-image, 
  /deep/ .van-uploader__upload {
    width: 65px;
    height: 65px;
    margin: 0px 5px 8px 7px;
  }

  /deep/ .el-form-item__label {
    padding-left: 5%;
    font-size: 16px;
  }

  /deep/ .upLoadPic {
    background-color: #fff;
    padding: 3% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /deep/ .van-uploader__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      /deep/ .van-uploader__preview-image {
        width: 62px;
      }
    }
  }

  .tjBtn { //提交按钮
    margin: 5%;
    width: 90%;
    background-color: #FD5C0C;
    border: 1px solid #FD5C0C;
    font-size: 16px;
    padding: 10px 12px;
  }

  .input1 {
    border: 1px solid transparent;
  }

  .radio1, 
  /deep/ .el-radio-group {
    padding: 0 15px;

    /deep/ .el-radio-button {
      width: 50%;
    }
  }

  /deep/ .el-radio-button--mini {
    /deep/ .el-radio-button__inner {
      padding: 7px 10px 7px 10px;
      font-size: 14px;
    }
  }

  .el-input {
    font-size: 16px;
  }

  /deep/ .el-radio__input.is-checked {
    /deep/ .el-radio__inner {
      border-color: #FD5C0C;
      background: #FD5C0C;
    }
  }

  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #FD5C0C;
  }
}
  .JDmap {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-sizing: border-box;
    border: 1px solid #ececec;
    .address {
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      z-index: 999;
      padding: 10px;
      border-radius: 15px;
      background: #FFF;
      /deep/ .el-input {
        margin-bottom: 10px;
      }
      /deep/ .el-input__inner {
        border: none;
        background: #f7f8fc;
      }
    }
    #container {
      width: 100%;
      height: 90%;
      border-radius: 15px 15px 0 0;
    }
  }  
</style>
